import apiEvents from 'src/player/events';
import Logger from 'src/util/logger';

const logger = new Logger('vhs:plugin:pause-other-players');

export default {
  name: 'pauseOtherPlayers',
  enable: true,
  proto: {
    _ready() {
      // copy config param to plugin for stubbing in tests
      this._allowMultiplePlayers = window.__VHS__.config.allowMultiplePlayers;
      this._player.on(apiEvents.PLAY, this.pauseOtherPlayers.bind(this));
      this._player.on(apiEvents.AD_STARTED, this.pauseOtherPlayers.bind(this));
      this._player.on(apiEvents.AD_RESUMED, this.pauseOtherPlayers.bind(this));
      this._player.on(apiEvents.MUTE, this.handleMute.bind(this));
    },

    // handle muting other players when unmuting
    handleMute() {
      const state = this._player.store.getState();
      const isMediaMuted = state.player?.isMediaMuted;
      const isPaused = !state.player?.isMediaPlaying;
      const instances = Object.keys(window.__VHS__.instances);
      const isAudio = state.player.options.mediaType === 'audio';

      // muted or paused we don't need to do anything
      if (isMediaMuted || isPaused) return;
      if (this._allowMultiplePlayers && !isAudio) {
        return;
      }

      instances.forEach(instanceId => {
        if (this._player.props.instanceId !== instanceId) {
          const inst = window.__VHS__.instances[instanceId];
          const instIsLive = inst.isLive();
          const instIsMuted = inst.isMuted();
          // pause non live videos if this video isn't muted
          console.log(`instance:${instanceId}`, instIsLive, instIsMuted);
          if (!instIsLive && !instIsMuted) inst.pause();
          // mute the live instance because this player has been unmuted
          if (instIsLive && !instIsMuted) inst.mute(true);
        }
      });
    },

    pauseOtherPlayers() {
      const state = this._player.store.getState();
      const isMediaMuted = state.player?.isMediaMuted;

      const isAudio = state.player.options.mediaType === 'audio';
      const instances = Object.keys(window.__VHS__.instances);

      if (this._allowMultiplePlayers && !isAudio) {
        return;
      }

      if (instances.length > 1 && !this._allowMultiplePlayers) {
        logger.warn(
          'for simultaneous player playback, set allowMultiplePlayers=true on VHS options'
        );
      }

      instances.forEach(instanceId => {
        if (this._player.props.instanceId !== instanceId) {
          const inst = window.__VHS__.instances[instanceId];
          const instIsLive = inst.isLive();
          const instIsMuted = inst.isMuted();
          // pause non live videos if this video isn't muted
          if (!instIsLive && !isMediaMuted && !instIsMuted) inst.pause();
          // mute self if there is a live instance that isn't muted
          if (instIsLive && !instIsMuted) this._player.mute(true);
        }
      });
    },

    isPlayerMuted(player) {
      const isVHS3 = !!player.store;

      if (isVHS3) {
        return player.store.getState().player.isMediaMuted;
      }
      return player.isMuted();
    }
  }
};
